
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters({
      currentSite: 'getCurrentSite',
      currentSiteSettings: 'getCurrentSiteSettings'
    })
  },
  async asyncData({ $axios }) {
    let areas = [];
    // eslint-disable-next-line
    let posts = [];

    try {
      const blocksResponse = await $axios.$get("api/front/blocks");
      areas = blocksResponse.data.areas;
    } catch (e) {}

    /*   try {
      const postsResponse = await $axios.$get('api/front/posts?per_page=4')
      posts = postsResponse.data
    } catch (error) {
    } */

    return {
      areas,
      posts,
    };
  },
  methods: {
    isValidHttpUrl(string) {
      let url;

      try {
        url = new URL(string);
      } catch (_) {
        return false;
      }

      return url.protocol === "http:" || url.protocol === "https:";
    },
  },
  head() {
    return {
      title: this.$t("common.home_title"),
    };
  },
};
