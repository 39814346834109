
import YoutubeVideoModal from "~/modals/YoutubeVideoModal";

export default {
  components: {
    YoutubeIcon: () => import("~/assets/svg/heroicons/youtube.svg?inline"),
  },
  props: {
    isMainBanner: {
      type: Boolean,
      default: false,
    },
    headlineTag: {
      type: String,
      default: "h4",
    },
    appearVisible: {
      type: Boolean,
      default: false,
    },
    headline: {
      type: String,
      default: null,
    },
    subheadline: {
      type: String,
      default: null,
    },
    links: {
      type: Array,
      default: () => [],
    },
    image: {
      type: String,
      default: null,
    },
    imageAsBackground: {
      type: Boolean,
      default: false,
    },
    // @TODO: temporary, remove and use video
    backgroundVideo: {
      type: String,
      default: null,
    },
    video: {
      type: String,
      default: null,
    },
    colors: {
      type: Object,
      default: () => ({
        background:
          "linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, #3a3939 100%)",
        text_color: "#FFFFFF",
      }),
    },
    productSection: {
      type: Boolean,
      default: false,
    },
    overlay: {
      type: Boolean,
      default: false,
    },
    position: {
      type: String,
      default: "center_middle",
    },
    isTextShadow: {
      type: Boolean,
      default: false,
    },
    textShadows: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    styles() {
      return {
        background: this.colors.background,
        color: this.colors.text_color,
      };
    },
    textAlignmentClass() {
      if (!this.imageAsBackground) return "text-center";

      switch (this.position) {
        case "left_top":
          return "text-left";
        case "left_middle":
          return "text-left w-[430px]";
        case "left_bottom":
          return "text-left";
        case "center_top":
          return "text-center";
        case "center_middle":
          return "text-center";
        case "center_bottom":
          return "text-center";
        case "right_top":
          return "text-right";
        case "right_middle":
          return "text-right w-[430px]";
        case "right_bottom":
          return "text-right";

        default:
          return "text-center";
      }
    },
    middleContentAlignment() {
      if (!this.imageAsBackground)
        return "flex justify-center items-center flex-col pt-[64px] md:pt-[80px] ";

      switch (this.position) {
        case "left_top":
          return "flex flex-col justify-start rtl:items-end ltr:items-start pt-16 md:pt-10";
        case "left_middle":
          return "flex flex-col justify-center rtl:items-end ltr:items-start";
        case "left_bottom":
          return "flex flex-col justify-end rtl:items-end ltr:items-start pb-[64px] md:pb-[80px]";
        case "center_top":
          return "flex flex-col justify-start items-center pt-16 md:pt-10 ";
        case "center_middle":
          return "flex flex-col justify-center items-center";
        case "center_bottom":
          return "flex flex-col justify-end  items-center pb-[64px] md:pb-[80px]";
        case "right_top":
          return "flex flex-col justify-start rtl:items-start ltr:items-end pt-16 md:pt-10";
        case "right_middle":
          return "flex flex-col justify-center rtl:items-start ltr:items-end";
        case "right_bottom":
          return "flex flex-col justify-end rtl:items-start ltr:items-end pb-[64px] md:pb-[80px]";

        default:
          return "flex flex-col justify-start items-center pt-16 md:pt-10 ";
      }
    },
  },
  mounted() {
    if (process.browser) {
      this.$nextTick(() => {
        const videos = document.querySelectorAll("video");

        if (videos.length >= 1) {
          const options = {
            rootMargin: "0px 0px -20% 0px",
          };

          const observer = new IntersectionObserver((entries, observer) => {
            entries.forEach((entry) => {
              // eslint-disable-line arrow-parens
              if (entry.isIntersecting) {
                entry.target.play();
              } else {
                entry.target.pause();
              }
            });
          }, options);

          Array.prototype.forEach.call(videos, (video) => {
            // eslint-disable-line arrow-parens
            observer.observe(video);
          });
        }
      });
    }
  },
  methods: {
    openVideoModal() {
      this.$modal.show(
        YoutubeVideoModal,
        {
          source: this.video,
        },
        {
          adaptative: true,
          maxWidth: 2560,
          width: "90%",
          height: "auto",
        }
      );
    },
    hasSlot(name = "default") {
      return !!this.$slots[name] || !!this.$scopedSlots[name];
    },
  },
};
