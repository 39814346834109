
export default {
  props: {
    title: {
      type: String,
      default: null
    },
    modalBodyClass: {
      type: [Array, String],
      default: 'overflow-y-scroll px-4 py-2'
    }
  },
  methods: {
    hasSlot (name = 'default') {
      return !!this.$slots[name] || !!this.$scopedSlots[name]
    }
  }
}
