
export default {
  props: {
    multiple: {
      type: Boolean,
      default: false,
    },
    hero: {
      type: Boolean,
      default: false,
    },
  },
};
